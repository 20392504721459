import { AppConstants } from '../../constants/AppConstants';
import { STORAGE } from '../constants/keys';
import { getLocalStorage } from './localStorage';

import _ from 'lodash';

export const isChina = () => getLocalStorage(STORAGE.ISCHINA);

export const getImageUrl = (image, checkExist) => {
    const prefix = isChina() ? AppConstants.CHINA_CDN.IMAGE : AppConstants.LOCAL_PATH.IMAGE;
    let imageUrl = prefix + image;

    // if image doesn't exist in cdn, use local image
    if (checkExist && !checkImageExists(imageUrl)) imageUrl = AppConstants.LOCAL_PATH.IMAGE + image;

    return imageUrl;
};

const checkImageExists = (image_url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);

    http.send();

    return http.status !== 404;
};

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const isLandscape = () => {
    return detectMobile() && window.innerWidth > window.innerHeight;
};

export function setIsLandscape(isLandscapeSite) {
    window.isLandscape = isLandscapeSite && isLandscape();
    // window.isLandscape = isLandscape();
}

export function getIsLandscape() {
    return window.isLandscape;
}

export const getVideoLinksByLanguage = (links, language) => {
    const langCode = language.split('-')[1];
    const lang = _.has(links, langCode) ? langCode : 'US';

    return isChina() ? links[lang].CN : links[lang].EN;
};
