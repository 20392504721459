import React from 'react';

import { AppConstants } from '../scripts/constants/AppConstants';
import { Clib } from '../scripts/game-logic/clib';
import { IntlProvider } from '../scripts/components/internationalisation/IntlProvider';
import { TopBar } from '../scripts/components/TopBar';
import { getIsLandscape, isChina } from '../scripts/common/helpers/misc';
import { getTranslation } from '../scripts/common/helpers/locale';
import ErrorPage from '../scripts/components/ErrorPage';

class NotFound extends React.Component {
    _cssRoot = AppConstants.LOCAL_PATH.CSS.ROOT;
    _cdnCss = AppConstants.LOCAL_PATH.CSS.CDN_FILE;
    _isLandscape = getIsLandscape();

    loadThemeCss = (theme) => {
        if (theme && theme !== '') {
            var themeFile = this._cssRoot + theme + 'Theme.css';
            Clib.loadCss(themeFile, 'theme-' + theme);

            if (isChina()) {
                Clib.loadCss(this._cdnCss + theme + 'Theme.css', 'theme-' + theme + '-cdn');
            }
        }

        if (this._isLandscape) {
            Clib.loadCss(this._cssRoot + 'landscapeTheme.css', 'theme-landscape');

            if (isChina()) {
                Clib.loadCss(this._cdnCss + 'landscapeTheme.css', 'theme-landscape-cdn');
            }
        }
    };

    render() {
        let refererUrl = document.referrer;
        if (refererUrl === '' || refererUrl === undefined) {
            refererUrl = window.location.origin;
        }

        let backUrl = this.props.backUrl;
        if (this.props.backUrl === null || refererUrl === this.props.backUrl) {
            backUrl = null;
        }

        this.loadThemeCss(this.props?.theme ?? 'blue');
        return (
            <IntlProvider language={this.props?.language ?? 'en-US'}>
                <div id="game-wrapper">
                    <div id="game-content">
                        <div id="game-container">
                            <div id="game-inner-container">
                                {!this._isLandscapeAndSaba && (
                                    <TopBar
                                        isMobileOrSmall={true}
                                        title={getTranslation('CRASH')}
                                        isMain={true}
                                        back_url={backUrl}
                                        theme={this.props.theme}
                                    />
                                )}
                                <div id="loading-container" style={{ backgroundColor: '#223745' }}>
                                    <div id="loading-image">
                                        <ErrorPage
                                            theme={this.props.theme ?? 'blue'}
                                            sessionInvalid={this.props.sessionInvalid}
                                            errorMsg={this.props.errorMsg}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

export default NotFound;
