export const ERROR_REDIRECT_STATUSES = [401, 403, 404, 451, 502, 503, 504];

export const errorStatusesMap = new Map([
    [
        '400',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '400 error text',
            title: 'Bad request',
        },
    ],
    [
        '401',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '401 error text',
            title: 'Unauthorized',
        },
    ],
    [
        '403',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '403 error text',
            title: 'Forbidden',
        },
    ],
    [
        '404',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: '404 error text',
            title: 'Page not found',
        },
    ],
    [
        '451',
        {
            // image: ICONS.ERROR.ERROR_4XX,
            text: 'Blocked country text',
            title: 'Sorry, your IP is prohibited',
        },
    ],
    [
        '500',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '500 error text',
            title: 'Internal server error',
        },
    ],
    [
        '502',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '502 error text',
            title: 'Bad gateway',
        },
    ],
    [
        '503',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '503 error text',
            title: 'Service unavailable',
        },
    ],
    [
        '504',
        {
            // image: ICONS.ERROR.ERROR_5XX,
            text: '504 error text',
            title: 'Gateway timeout',
        },
    ],
]);

export const THEMES = {
    BLACK: 'black',
    ROCKET: 'rocket',
    BLUE_THEME: {
        BLUE: 'blue',
        EURO2020: 'euro2020',
    },
};

export const PAGINATION_MODES = {
    DEFAULT: 'default',
    ADVANCE: 'advance',
};

export const HTTP_HEADER = {
    CHINA_IDENTIFIER: 'WANGSU-REQ-ID',
};

export const DEFAULT = {
    THEME: THEMES.BLUE_THEME.BLUE,
};

export const INFINITY = '∞';

// China using wistia
export const HOW_TO_PLAY_VIDEO_LINKS = {
    US: {
        EN: 'https://www.youtube.com/embed/16KM31Koux0?controls=1',
        CN: 'n6a9ajsjkn',
    },
    CN: {
        EN: 'https://www.youtube.com/embed/kskQmuixfXY?controls=1',
        CN: '14d8k14cs3',
    },
    TH: {
        EN: 'https://www.youtube.com/embed/4zsW_dqkEE4?controls=1',
        CN: 'h2ma1lp8z6',
    },
    ID: {
        EN: 'https://www.youtube.com/embed/w2xX8rb1ZNA?controls=1',
        CN: 'i1bb08dwq0',
    },
    VN: {
        EN: 'https://www.youtube.com/embed/d2JiH8PeUbo?controls=1',
        CN: 'abiagc74g1',
    },
};
