import React from 'react';

import PropTypes from 'prop-types';

import { GameSettingsStore } from '../stores/GameSettingsStore';
import { Image } from './Image';
import { THEMES } from '../common/constants/misc';
import { getTranslation } from '../common/helpers/locale';
import GameSettingsActions from '../actions/GameSettingsActions';
import MComponent from '../../views/MComponent';

function getState() {
    return {
        theme: GameSettingsStore.getCurrentTheme(), //black || white
    };
}

export class TopBar extends MComponent {
    constructor(props) {
        super(props);
        this.state = getState();
        this.state.fullScreen = false;
    }

    static propTypes = {
        isMain: PropTypes.bool.isRequired,
        // isMobileOrSmall: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
    };

    componentDidMount() {
        GameSettingsStore.on('all', this._onChange);
    }

    componentWillUnmount() {
        GameSettingsStore.off('all', this._onChange);
    }

    _onChange = () => {
        this.setState(getState());
    };

    _toggleTheme = () => {
        GameSettingsActions.toggleTheme();
    };

    _toggleFullScreen = () => {
        window.screenfull.toggle();
        this.setState({ fullScreen: !this.state.fullScreen });
    };

    back = () => {
        if (this.props.back_url && this.props.back_url !== undefined) {
            window.location = this.props.back_url;
        } else {
            this._goBack();
        }
    };

    render() {
        var back = this.props.back_url && this.props.back_url !== null && (
            <div className="back" onClick={this.back}>
                <Image src="back-icon.svg" alt="back" />
            </div>
        );

        return (
            <div id="top-bar">
                <div className="title">
                    {back}
                    {/* { (this.props.isMain) ? userLogin : '' } */}
                    <a href={this.props.title_url ? this.props.title_url : '/'}>
                        <div
                            className={`title-container  ${
                                this.props.title === getTranslation('CRASH') ? 'main-title' : ''
                            }`}
                        >
                            {(this.props.theme ?? this.state.theme) ===
                                THEMES.BLUE_THEME.EURO2020 &&
                            this.props.title === getTranslation('CRASH') ? (
                                <>
                                    <h1>{this.props.title} x</h1>
                                    <Image src="euro/euro-logo.png" alt="Euro" />
                                </>
                            ) : (
                                <h1>{this.props.title}</h1>
                            )}
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}
